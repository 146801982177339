'use client';

import { useRouter } from 'next/navigation';
import { useCallback } from 'react';
import {
    CURRENCY_LIST,
    formatTranslation,
    LOCALE_NAME,
    SHIPPABLE_COUNTRIES,
    SHIPPABLE_COUNTRY_FLAGS,
    SHIPPABLE_COUNTRY_NAMES,
    ShippableCountry,
    SUPPORTED_LANGUAGES,
    useLocalisation,
    useMixpanel,
    useTranslation,
    useUrlParams,
} from '@lib/machine-parts/storefront/utils';
import { flagIconMapping } from '@lib/machine-parts/storefront/ui';
import { LanguageSelectionPopup } from '@lib/machine-parts/storefront/layout/ui';
import { HeaderSanityData } from '@lib/machine-parts/storefront/layout/data-access';
import { useCheckout as useCheckoutV2 } from '@lib/machine-parts/storefront/checkout/data-access';
import { CountryCode } from '@lib/machine-parts/types/graphql/storefront';
import { useCart } from '@shopify/hydrogen-react';

interface LanguageSelectorProps {
    translation?: HeaderSanityData['region_selector'];
}

const languageOptions = SUPPORTED_LANGUAGES.map((x) => {
    const FlagIcon = flagIconMapping[x];
    return {
        name: LOCALE_NAME[x],
        value: x,
        icon: <FlagIcon />,
    };
});
const regionOptions = [...SHIPPABLE_COUNTRIES].sort().map((x) => ({
    name: SHIPPABLE_COUNTRY_NAMES[x],
    value: x,
    detail: CURRENCY_LIST[x],
    icon: SHIPPABLE_COUNTRY_FLAGS[x],
}));

export function LanguageSelector({ translation }: LanguageSelectorProps) {
    const { i18n } = useTranslation();
    const mixpanel = useMixpanel();
    const { buildUrl } = useUrlParams();
    const navigate = useRouter();
    const { path: unlocalisedPathname, language, locale } = useLocalisation();
    const t = formatTranslation(translation);
    const { updateCountry } = useCheckoutV2();
    const { buyerIdentityUpdate } = useCart();

    const changeSettings = useCallback(
        async (language: string, region: string) => {
            mixpanel.track(`Region Popup Change Button`, { language, region });
            await i18n.changeLanguage(language.toLowerCase());
            navigate.replace(buildUrl({ path: `/${language}/${region}${unlocalisedPathname}` }).url);
            buyerIdentityUpdate({
                countryCode: region as CountryCode,
            });
            updateCountry(region as CountryCode);
            navigate.refresh();
        },
        [buildUrl, buyerIdentityUpdate, i18n, mixpanel, navigate, updateCountry, unlocalisedPathname],
    );

    return (
        <LanguageSelectionPopup
            availableLanguages={languageOptions}
            initialSelectedLanguage={language}
            initialSelectedRegion={locale as ShippableCountry}
            availableRegions={regionOptions}
            onChange={changeSettings}
            content={{
                title: t('title', { allowUndefined: true }),
                description: t('description', { allowUndefined: true }),
                languageLabel: t('language_label', { allowUndefined: true }),
                regionLabel: t('region_label', { allowUndefined: true }),
                saveButtonLabel: t('save_button_label', { allowUndefined: true }),
                cancelButtonLabel: t('cancel_button_label', { allowUndefined: true }),
                disclaimer: t('disclaimer', { allowUndefined: true }),
            }}
        />
    );
}
